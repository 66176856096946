<template>
    <div v-loading="loading">
        <div class="detail-title">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>教研中心</el-breadcrumb-item>
                <el-breadcrumb-item :to="{ path: '/evaluation/details/' + $route.params.id }">测评详情</el-breadcrumb-item>

                <el-breadcrumb-item>评分列表</el-breadcrumb-item>
                <el-breadcrumb-item>{{ grade }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="padding-t-sm padding-b-sm" style="    text-align: right;">
            <span style="margin-right: 20px;">
                <el-button
                    @click="$router.push('/evaluation/details/' + $route.params.id + '/scoreConfiguration/' + id + '/scoreConfigurationAdd')"
                    type="primary" size="small">新增</el-button>

            </span>
        </div>
        <div class="margin-sm">
            <el-table :header-cell-style="{ padding: 0 }" :cell-style="{ textAlign: 'center' }" ref="singleTable"
                :data="tableData" highlight-current-row  style="width: 100%; background: #fff">
                <el-table-column label="ID" property="id" align="center" width="80">
                </el-table-column>



                <el-table-column label="类型" property="read_content" align="center">
                    <template slot-scope="scope">
                        <span>{{ type[scope.row.type - 1] }}</span>
                    </template>
                </el-table-column>

                <el-table-column label="最低分数" property="min_score" align="center">
                </el-table-column>

                <el-table-column label="最高分数" property="max_score" align="center">
                </el-table-column>


                <el-table-column label="建议" property="suggest" align="center">
                </el-table-column>

                <el-table-column label="标签" property="tag" align="center">
                </el-table-column>


                <el-table-column label="创建时间" property="created_at" align="center">
                </el-table-column>


                <el-table-column label="更新时间" property="updated_at" align="center">
                </el-table-column>

                <el-table-column label="操作" align="center">
                    <template slot-scope="scope">
                        <span style="margin-right: 5px">
                            <el-link type="primary"
                                @click="$router.push('/evaluation/details/' + $route.params.id + '/scoreConfiguration/' + id + '/scoreConfigurationEdit/' + scope.row.id)">编辑</el-link>
                        </span>
                        <span style="margin-right: 5px">
                            <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                @confirm="deleted(scope.row)">
                                <el-link slot="reference" type="primary">删除</el-link>
                            </el-popconfirm>
                        </span>
                    </template>
                </el-table-column>
            </el-table>
            <div class="table-batch">
                <span class="fl-l">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>


        </div>
    </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "scoreConfiguration",
    data() {
        return {
            loading: false,
            page: 1,
            pageSize: 10,
            total: 0,
            tableData: [],
            id: null,
            type: ['自主阅读', '综合理解', '阅读速度', '信息提取', '潜在信推断'],
            grade: '',
        };
    },
    methods: {
        ...mapActions("evaluation", [
            "evaluationItemScoreList",
            "delEvaluationItemScore",
            "getEvaluationItem"
        ]),

        async deleted(row) {
            const { res_info } = await this.delEvaluationItemScore({ id: row.id })
            if (res_info != 'ok') return
            this.$message.success("删除成功！");
            this.getList();
        },
        async getList() {
            this.loading = true;
            let form = {
                id: this.id,
                page: this.page,
                pageSize: this.pageSize,
            };
            const { data } = await this.evaluationItemScoreList(form);
            const res = await this.getEvaluationItem({ id: this.id })
            this.grade = res.data.grade
            this.tableData = data.list;
            this.total = data.total;
            console.log(data);
            this.loading = false;
        },

        setPageSize(pageSize) {
            this.pageSize = pageSize;
            this.getList();
        },
        setPage(page) {
            this.page = page;
            this.getList();
        },

    },
    mounted() {
        this.id = this.$route.params.classId;
        this.getList();
    },
};
</script>

<style>
.course-detail-title {
    margin: 10px;
    background: #fafafa;
    overflow: hidden;
}

.title {
    font-size: 18px;
    margin-top: 5px;
}

.price {
    margin-top: 45px;
    font-size: 16px;
}
</style>
